<template>
  <div class="pay">
    <div class="pay-info">
      <div class="pay-info-title" v-text="name" />
      <div class="pay-info-price">￥<span class="pay-info-price-num" v-text="temp.money" /></div>
      <div class="pay-info-tiem"><span class="pay-info-tiem-text">支付剩余时间：</span>
        <van-count-down class="pay-info-tiem-down" :time="time" format="mm:ss" />
      </div>
    </div>
    <van-radio-group v-model="tradeType" class="radio-group">
      <van-cell-group>
        <div v-for="(item,index) in temp.modules" :key="index" class="group-item-content">
          <van-image :src="item.typeLogo" class="logo" />
          <van-cell :title="item.typeName" clickable @click="tradeType = item.tradeType">
            <template #right-icon>
              <van-radio checked-color="#ee0a24" icon-size="14px" :name="item.tradeType" />
            </template>
          </van-cell>
        </div>
      </van-cell-group>
    </van-radio-group>
    <van-button class="pay-button" round color="#F7263C" text="去支付" :loading="buttonLoading" @click="pay" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Cell, CellGroup, RadioGroup, Radio, CountDown, Button, Toast, Image } from 'vant'

Vue.use(Cell).use(CellGroup).use(Radio).use(RadioGroup).use(CountDown).use(Button).use(Toast).use(Image)
import { weChatPay, aliPayByForm } from '@/utils/payment'
import { getPayModule, getPayParams ,getNewPayParams} from '@/services/pay'
import { isWeChat } from '@/utils/common/validate'
import { getCheerPayModule, getCheerPayParams } from '@/services/comeOn'

export default {
  name: 'Pay',
  data() {
    return {
      ordersn: '',
      tradeType: 'BALANCE',
      time: 30 * 60 * 1000,
      name: '我的小店',
      temp: {},
      buttonLoading: false
    }
  },
  created() {
    this.getOrderInfo()
  },
  methods: {
    /**
     * @description: 获得订单信息
     * @Date: 2021-07-29 20:56:36
     * @author: 旭日
     */
    async getOrderInfo() {
      let res = ''
      const { orderNo, moduleType = 1 } = this.$route.query
      const moduleId = moduleType
      const type = isWeChat() ? (moduleId !== 1 && moduleId !== '1' ? 143 : 147) : 158
      if (moduleId !== 1 && moduleId !== '1') {
        res = await getCheerPayModule({ orderNo, type, moduleId })
      } else {
        res = await getPayModule({ orderNo, type, moduleId })
      }
      const { code, data } = res
      if (code === 200) {
        this.temp = data
        this.time = data.endTime * 1000 - new Date().getTime()
        this.tradeType = data.modules[0].tradeType
      }
    },
    /**
     * @description: 选择支付模式
     * @Date: 2021-07-29 20:54:48
     * @author: 旭日
     */
    async pay() {
      this.buttonLoading = true
      if (this.tradeType === 'BALANCE') {
        console.log('余额')
      } else {
        console.log('第三方')
        let res = ''
        let returnUrls = ''
        const { orderNo, goodsId, isLife, moduleType = 1 ,noToken} = this.$route.query
        const {
          mchId,
          tradeType,
          payChannel,
          mchAppId
        } = this.temp.modules.filter((item) => item.tradeType === this.tradeType)[0]
        if (moduleType !== 1 && moduleType !== '1') {
          returnUrls = 'https://web.yuetao.vip'
        } else {
          returnUrls = 'https://web.yuetao.vip/pay-consequent?orderNo=' + orderNo + '&goodsId=' + goodsId + '&isLife=' + isLife
        }
        const params = {
          orderNo, // 主单号
          mchId,
          payChannel,
          tradeType,
          appId: mchAppId,
          moduleId: moduleType, // 商城支付传1   余额充值传7
          openId: localStorage.getItem('openid'),
          returnUrl: returnUrls
        }
        if (moduleType !== 1 && moduleType !== '1') {
          res = await getCheerPayParams(params)
        } else {
          if(noToken){
            res = await getNewPayParams(params)
          }else{
            res = await getPayParams(params)
          }
          
        }
        const { code, data } = res
        if (code === 200) {
          switch (data.tradeType) {
              case 'QUICK_DEBIT':
          window.location.href =data.getwayUrl;
            case 'WX_JSWEB':
              this.wxPay(data.getwayBody, orderNo)
              break
            case 'WX_JSAPI':
              this.wxPay(data.getwayBody, orderNo)
              break
            case 'ALI_WAP':
              this.zfbPay(data.getwayBody.htmlForm)
              break
          }
        }
      }
      this.buttonLoading = false
    },
    /**
     * @description: 余额支付
     * @Date: 2021-07-30 15:30:40
     * @author: 旭日
     */
    balancePay() {
    },
    /**
     * @description: 京东云闪付
     * @Date: 2021-07-30 15:30:52
     * @author: 旭日
     */
    jdPay() {
    },
    /**
     * @description: 支付宝支付
     * @Date: 2021-07-30 15:29:47
     * @author: 旭日
     * @param {*} getwayBody 支付宝返回数据
     * @param {*} orderNo 订单号
     */
    zfbPay(getwayBody) {
      aliPayByForm(getwayBody)
    },
    /**
     * @description: 加油话费视频充值
     * @Date: 2021年09月24日15:49:17
     * @author: 旭日
     */
    isOrderType(status) {
      const that = this
      const { orderNo } = this.$route.query
      that.$router.push({
        path: '/oderSky',
        query: {
          payType: 2,
          ordersn: that.ordersn,
          payFormNo: orderNo,
          status: status
        }
      })
    },
    /**
     * @description: 微信支付
     * @Date: 2021-07-30 15:28:34
     * @author: 旭日
     * @param {*} getwayBody 微信返回数据
     * @param {*} orderNo 订单号
     */
    wxPay(params, orderNo) {
      const { moduleType = 1 } = this.$route.query
      if (moduleType !== 1 && moduleType !== '1') {
        const that = this
        weChatPay(params).then(res => {
          // 支付成功后的操作
          Toast('支付成功')
          // that.isDisabledSubmitBtn = true
          that.isOrderType('TRADE_SUCCESS')
        }).catch(e => {
          if (e) {
            const { msg = null } = e
            if (msg === 'isNotWeChat') {
              Toast('请在微信浏览器下进行支付')
            }
            if (msg === 'cancel') {
              // 取消支付的操作
              Toast('取消支付')
              that.isOrderType('UNKNOWN')
            }
            if (msg === 'fail') {
              // 支付失败的操作
              Toast('支付失败')
              that.isOrderType('UNKNOWN')
            }
          }
          // that.isDisabledSubmitBtn = false
        })
      } else {
        weChatPay(params).then(res => {
          // 支付成功后的操作 TRADE_SUCCESS
          this.$router.replace({
            path: '/pay-consequent',
            query: { orderNo, ...this.$route.query }
          })
        }).catch(e => {
          if (e) {
            const { msg = null } = e
            if (msg === 'isNotWeChat') {
              Toast('请在微信浏览器下进行支付')
            }
            if (msg === 'cancel') {
              // 取消支付的操作 UNKNOWN
              Toast('取消支付')
            }
            if (msg === 'fail') {
              // 支付失败的操作 UNKNOWN
              this.$router.replace({
                path: '/pay-consequent',
                query: { orderNo }
              })
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
* {
  touch-action: pan-y;
}

.pay {
  height: 100vh;
  background: rgb(246, 246, 246);

  .pay-nav-bar {
    /deep/ .van-icon {
      color: #000;
    }
  }

  .pay-info {
    padding: 15px;
    font-size: 13px;

    .pay-info-price {
      color: #F7263C;
      font-size: 16px;
      padding: 15px 0 10px;

      .pay-info-price-num {
        font-size: 26px;
      }
    }

    .pay-info-tiem {
      color: #999;

      .pay-info-tiem-text {
        display: inline-block;
      }

      .pay-info-tiem-down {
        display: inline-block;
        color: #999;
        vertical-align: middle;
      }
    }
  }

  .radio-group {
    text-align: left;

    .group-item-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        min-width: 22px;
        width: 22px;
        height: 22px;
        margin: 0 15px 0 10px;
      }

      /deep/ .van-cell {
        border-bottom: 1px solid #F2F2F2;
        padding-left: 0;
        padding-right: 0;
        margin-right: 15px;
        font-size: 13px;
      }
    }
  }

  .pay-button {
    position: fixed;
    left: 5vw;
    bottom: 20px;
    width: 90vw;
    font-size: 12px;
    box-sizing: border-box;
  }
}
</style>
